<template>
  <Layout>
    <div class="card p-5">
      <div class="text-center my-5" v-if="loadingForm">
        <h6>Preparing form, Please wait...</h6>
      </div>
      <FormulateForm v-else v-model="timeSchedule" @submit="submitForm">
        <FormulateInput
          type="group"
          name="days"
          :repeatable="true"
          label="Work time settings"
          add-label="+ Add Day"
          validation="required"
          v-model="days"
        >
          <div class="row">
            <div class="col-md-4">
              <FormulateInput
                :options="day"
                type="select"
                name="day"
                placeholder="Select a day"
                label="Day"
                validation="required"
              />
            </div>
            <div class="col-md-4">
              <FormulateInput
                type="time"
                name="start_time"
                label="start time"
                validation="required"
                :validation-messages="{
                  required: 'Start time is required',
                }"
              />
            </div>
            <div class="col-md-4">
              <FormulateInput
                type="time"
                name="end_time"
                label="End time"
                validation="required"
                :validation-messages="{
                  required: 'End time is required',
                }"
              />
            </div>
          </div>
        </FormulateInput>

        <div class="text-right my-4">
          <FormulateInput
            type="submit"
            :disabled="updating"
            :label="updating ? 'Changing...' : 'Change'"
          />
        </div>
      </FormulateForm>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { day } from "@/config/data/day.js";
import { job } from "@/config/api/partner/job";
export default {
  components: {
    Layout,
  },
  data() {
    return {
      timeSchedule: {},
      days: null,
      loadingForm: false,
      updating: false,
    };
  },
  created() {
    this.loadWorkstationTime();
  },
  computed: {
    day() {
      return day;
    },
    workStation() {
      return this.$store.getters["auth/workStation"];
    },
  },
  methods: {
    submitForm(val) {
      if (this.checkIsThereRedundant(val.days)) {
        this.$bvToast.toast("Please remove duplicate day", {
          title: "Working Time Update",
          variant: "danger",
          solid: true,
        });
        return;
      }
      this.updateTimeSlot(val);
    },
    loadWorkstationTime() {
      const api = job.loadWorkTime;
      api.id = this.workStation;
      this.loadingForm = true;
      this.generateAPI(api)
        .then((res) => {
          let time_slots = [];
          for (const [key, value] of Object.entries(
            res.data.workstation.working_time
          )) {
            time_slots.push({
              ...value,
              day: key,
            });
          }
          this.days = time_slots;
          this.timeSchedule.days = time_slots;
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    /*eslint-disable */

    checkIsThereRedundant(arr) {
      const visited = [];
      let duplicated = false;
      for (let i = 0; i < arr.length; i++) {
        if (visited.includes(arr[i].day)) {
          duplicated = true;
          break;
        } else {
          visited.push(arr[i].day);
        }
      }
      return duplicated;
    },
    /*eslint-enable */
    updateTimeSlot(data) {
      const api = job.updateTime;
      let postProcessed = {};
      data.days.forEach((ele) => {
        postProcessed[ele.day] = {
          start_time: ele.start_time,
          end_time: ele.end_time,
        };
      });
      api.data = { working_time: postProcessed };
      this.updating = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Work time updated successfully", {
            title: "Working Time Update",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Work time updated is not updated!${err.response.data}`,
            {
              title: "Working Time Update",
              variant: "success",
              solid: true,
            }
          );
          this.loadWorkstationTime()
        })
        .finally(() => {
          this.updating = false;
        });
    },
  },
};
</script>

<style></style>
